.loader {
	width: 100px;
	height: 100px;
	border: 3px solid #06a7e2;
	border-radius: 50%;
	display: inlinef-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader::after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	left: 0;
	top: 0;
	background: #e43c09;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	border-radius: 50%;
}
