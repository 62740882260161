/*
source
http://stackoverflow.com/a/23935891/3853728
*/

.img-gradient {
	position: relative;
	display: inline-block;
}

/* 
  #002f4b,#dc4225 
  Convert HEX to RGBA - http://hex2rgba.devoth.com/
  */
.img-gradient:after {
	content: "";
	position: absolute;
	bottom: 8px;
	right: 8px;
	width: 100%;
	height: 310px;
	display: inline-block;
	background: -moz-linear-gradient(
		top,
		rgba(163, 183, 194, 0.5) 0%,
		rgba(155, 111, 102, 0.8) 100%
	); /* FF3.6+ */
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, rgba(148, 69, 53, 0.5)),
		color-stop(100%, rgba(0, 47, 75, 0.5))
	); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(
		top,
		rgba(0, 47, 75, 0.5) 0%,
		rgba(220, 66, 37, 0.5) 100%
	); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(
		top,
		rgba(0, 47, 75, 0.5) 0%,
		rgba(220, 66, 37, 0.5) 100%
	); /* Opera 11.10+ */
	background: -ms-linear-gradient(
		top,
		rgba(0, 47, 75, 0.5) 0%,
		rgba(220, 66, 37, 0.5) 100%
	); /* IE10+ */
	background: linear-gradient(
		to bottom,
		rgba(0, 47, 75, 0.5) 0%,
		rgba(220, 66, 37, 0.5) 100%
	); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.img-gradient img {
	display: block;
}

.picOfMe {
	width: 250px;
	bottom: 8px;
	right: 8px;
}
