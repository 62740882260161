.ShowToast {
	width: 20%;
	position: fixed;
	left: 50%;
	margin-left: -10%;
	bottom: 8%;
	animation-name: slideUpBottom;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
}

.HideToast {
	animation-name: slideDownBottom;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
}

@keyframes slideUpBottom {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideDownBottom {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(100%);
		opacity: 0;
	}
}
