.header {
	color: #ffffff;
	background-color: theme("colors.coolGray.800");
	position: fixed;
	width: 100%;
	height: 85px;
	transition: transform 0.5s ease-in-out;
	z-index: 1001;
}

.header-unpin {
	/* display: none; */
	transform: translateY(-65px);
}
.header-pin {
	/* display: block; */
	transform: translateY(0);
}
