.flip-card {
	background-color: transparent;
	width: 320px;
	height: 300px;
	perspective: 1000px;
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	border-radius: 8px;
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 8px;
}

.flip-card-front {
	background-color: theme("colors.coolGray.700");
}

.flip-card-back {
	background-color: theme("colors.coolGray.800");
	color: black;
	border: solid 2px theme("colors.coolGray.200");
	transform: rotateY(180deg);
}